import { render, staticRenderFns } from "./m-scroll-top.vue?vue&type=template&id=c506490e&scoped=true&"
import script from "./m-scroll-top.vue?vue&type=script&lang=js&"
export * from "./m-scroll-top.vue?vue&type=script&lang=js&"
import style0 from "./m-scroll-top.vue?vue&type=style&index=0&id=c506490e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c506490e",
  null
  
)

export default component.exports